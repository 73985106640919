import { MoodReadResponse, PostCreateRequest, PostReadResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { execProgressiveHandler, progressiveHandler } from "../../ProgressiveHandler";

import { initializePostUpload, uploadStages } from "./steps";
import { add, omit } from "lodash";
import { Signal, signal } from "@preact/signals-react";
import { cache, EnrichedPost, PostUploadState } from "../..";
import { updateUploadStatus, wait } from "./helpers";
import { _current, win } from "../auth";
import { websocketEvents } from "../../eventHandlers";

// import { createPostSingle } from "../post";

export type UploadProgressEntry = {
  id?: string;
  localId: string;
  file?: Blob;
  progress: "scheduled" | "processing" | "error";
};


export const uploadQueueSignal = signal<string[]>([]) as Signal<string[]>;
export const uploadResponseSignal = signal<PostReadResponse>({}); // as DeepSignal<PostReadResponse>;

const UPLOAD_STAGES = ["created", "attached", "uploaded"] as PostUploadState["status"][];

const execStage = async <T>(
  stageName: PostUploadState["status"],
  id: string,
  processor: (post: EnrichedPost) => Promise<T | undefined>
) => { // { name: PostUploadState["status"], processor: () => Promise<T | undefined>, provisionalPost: Partial<EnrichedPost>, foldersToAttach: MoodReadResponse[]) => {
  try {
    const post = await cache.post.get(id) as EnrichedPost;

    const stageIx = UPLOAD_STAGES.indexOf(stageName);
    const postStateIx = UPLOAD_STAGES.indexOf(post.uploadState?.status || "" as any);

    if (postStateIx + 1 != stageIx)
      return Promise.resolve();

    // let r: T | undefined;
    if (postStateIx + 1 == stageIx) {
      await processor(post);
      await updateUploadStatus(stageName, post, post.uploadState?.foldersToAttach || []);
    }
  } catch (ex) {
    console.error("Upload error", ex)
  }


  // return r;
}


// // electron
setTimeout(() => {
  if (typeof window != "undefined") {
    window.addEventListener('message', function (event: any) {
      // if(1) return;
      
      const content = event.data.content;
      // const moodId = event.data.moodId;
      if (!event.data.foldersToAttach) {
        return
      }
      execProgressiveHandler(createPostMultiple, { ...event.data, aiGenerated: true });
    });
  
    websocketEvents.on("newgraph-notification", (ev) => {
      const pl = ev?.payload;
      if(pl.aiGenerated)
        return;
  
      if (ev?.type == "newgraph" && pl.message == "post_in_folder") {
        const post = ev.payload.post; 
        const cnt = post.content || post.description;
  
        // if (!(postForm as any).auto)
        const address = "/" + _current.value.username;
        const addressingCurrent = cnt.trim().startsWith(address);
        if(addressingCurrent)
          parent.postMessage({ post: { ...post, content: cnt.trim().replace(new RegExp("^" + address), ""), foldersToAttach: [post.moodId] } }, "*")
      }
    });
  }
  
})



export const createPostSingle = () =>
  progressiveHandler(
    { name: "createPostSingle" },
    uploadResponseSignal,
    async (
      progress,
      uploadResponseSignal,
      params: { id: string }
      //postForm: PostCreateRequest & { file: any; foldersToAttach?: string[] }
    ) => {
      const id = params.id || uploadQueueSignal.value.at(-1) || "";

      if (!id)
        return progress;

      // parent.postMessage(p, "*");

      for (let i = 0; i < uploadStages.length; i++) {
        const stageName = UPLOAD_STAGES[i];
        const processor = uploadStages[i]
        if (!processor)
          throw new Error("Bad processor");

        await execStage(stageName, id, processor);

        await wait(50);
      }

      const doNextJob = () => {
        const uqsl = uploadQueueSignal.value?.length || 0;
        uploadQueueSignal.value = [...uploadQueueSignal.value.slice(0, uqsl - 1)];

        uploadQueueSignal.value.length && uploader.value.exec({});
      };

      setTimeout(doNextJob);

      return progress;
    }
  );

const [_, uploader] = createPostSingle();

type ContentMode = "last" | "first" | "each";

type CreatePostParams = PostCreateRequest & {
  files: any[];
  foldersToAttach?: [];
  contentMode: ContentMode;
};


const preQueue = [] as CreatePostParams[];


export const _createPostMultiple = () =>
  progressiveHandler(
    { name: "create-post-multiple" },
    uploadQueueSignal,
    async (
      progress,
      uploadQueueSignal,
      _postForm: CreatePostParams
    ) => {
      const postForm = preQueue.pop();
      if (!postForm) {
        await Promise.resolve();
        return progress;
      }

      const fs = [...(postForm.files || [])];
      // const last = fs.pop();

      const tmplt = (f?: any, content?: string) => ({
        localId: Date.now().toString(),
        created: new Date().toISOString(),
        file: f,
        contentUrl: "",
        content: content || "",
        progress: "scheduled",
        foldersToAttach: [...(postForm.foldersToAttach || [])],
        auto: (postForm as any).auto
      });

      const cm = postForm.contentMode;
      const byContentMode = (i: number) =>
        cm == "each" ||
          (cm == "first" && i == 0) ||
          (cm == "last" && i == fs.length - 1)
          ? postForm.content
          : "";

      const queue = fs.map(
        (f, i) =>
          ({
            ...tmplt(f, byContentMode(i)),
          }) as UploadProgressEntry
      );

      // queue.push({
      //   ...tmplt(last),
      //   content: postForm.content || "",
      // } as UploadProgressEntry);

      const idqueue = [] as string[];
      for (const form of queue) {
        const { post } = await initializePostUpload(form);
        idqueue.unshift(post.id); // the whole queue is managed left to right
        await wait(20);
      }

      if (!queue?.length) {
        const nonMediaPostForm = tmplt(undefined, postForm.content);
        const { post } = await initializePostUpload(nonMediaPostForm)

        idqueue.push(post.id)
        await wait(20);
      }

      uploadQueueSignal.value = [...idqueue, ...uploadQueueSignal!.value];

      uploader.value.exec({}); // semaphore will prevent duplicate execution

      return { ...progress, done: false };
    }
  );



export const createPostMultiple = () =>
  progressiveHandler(
    { name: "create-post-multiple-queue" },
    uploadQueueSignal,
    async (progress, cache, postForm: CreatePostParams) => {

      // const p = await cache.post.get(id) as EnrichedPost

      preQueue.unshift(postForm);
      await execProgressiveHandler(_createPostMultiple, {});
      await Promise.resolve();


      // electron - direct
      // if (!(postForm as any).auto) { // dont autoreply to autoreplies {
      //   const inf = await (window as any).newgraphLocal.inference(postForm); // why repeat? because calling progressiveHandler recursively is prevented by .inProgress

      //   await execProgressiveHandler(createPostMultiple, { ...inf, auto: true });
      // }
      // electron - iframe


      return progress;
    }, {
    maxConcurrency: undefined
  }
  );
