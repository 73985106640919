import { PostCreateRequest } from "@newstackdev/iosdk-newgraph-client-js";
import { omit } from "lodash";
import { cache, EnrichedPost, newgraphClient, PostUploadState } from "../..";
import { execProgressiveHandler } from "../../ProgressiveHandler";
import { resizeImage } from "../../utils/resizeImage";
import { current, win } from "../auth";
import { retry, updateUploadStatus, uuidv4, wait } from "./helpers";
import { cachePosts } from "../post";
import { attachToFolders, createFolder, folderPosts, folderQuery } from "../folder";

export const initializePostUpload = async (
  postForm: PostCreateRequest & { file?: any; foldersToAttach?: string[] }
) => {
  // if((postForm as EnrichedPost).uploadState)

  const shouldUpload = postForm.contentType !== "text/plain";

  const f = postForm?.file;

  if (!shouldUpload && !postForm.content) {
    throw new Error(
      "post-create: Post must have either content or an attached file"
    );
  } else if (f) {
    if (!f.type) {
      throw new Error(
        "post-create: Unrecognized/unsupported content type. Upload something else."
      );
    }
  }

  if (f && f?.type) postForm.contentType = f.type;

  const purePost = omit(postForm, ["file", "foldersToAttach", "localId"]);

  const provisionalId = purePost?.id || uuidv4();
  const now = new Date().toISOString();

  const contentType = postForm.contentType || "text/plain";

  const base64thumb = f && (f.type || "").startsWith("image") && (await resizeImage(f));

  const provisionalPost = {
    id: provisionalId,
    ...purePost,
    thumbUrl: f?.preview,
    contentUrl: f ? base64thumb : "",
    label: "post" as "post",
    author: await execProgressiveHandler(current),
    updated: now,
    created: now,
    ...(contentType == "text/plain" ? { contentType } : {}),
    uploadState: {
      blob: f?.originFileObj,
      filename: f?.name,
      thumb: base64thumb,
      done: false,
      status: "preparing",
      foldersToAttach: (postForm.foldersToAttach || []).map(id => ({ id }))
    } as PostUploadState,
    moodId: postForm.foldersToAttach![0]
  };

  if (f && !f.thumbUrl) f.thumbUrl = provisionalPost.uploadState.thumb;

  await cachePosts(provisionalPost, (postForm?.foldersToAttach || []).map(id => ({ id })), ["attachment"]);

  const testread = await cache.post.get(provisionalPost.id);
  console.log(testread);

  return { post: provisionalPost, provisionalPost, foldersToAttach: postForm.foldersToAttach?.map((f) => ({ id: f })) };
};



const createPostOnServer = async (post: EnrichedPost) => {
  const f = await folderQuery(post.moodId!);
  if((f as any).future)
    await execProgressiveHandler(createFolder, omit(f, ["future", "autor"]));

  const p = await retry(() =>
    newgraphClient.api.post.postCreate({
      ...omit(post, ["thumbUrl"]),
      contentUrl: "preparing",
      moodId: post.moodId
    } as any)
  );

  // return p;
};

const updateAndAttach = async (
  post: EnrichedPost
) => {
  await retry(() =>
    attachToFolders(
      { ...post },
      post.uploadState?.foldersToAttach || []
    )
  );
};

const uploadFile = async (
  post: EnrichedPost
) => {
  const blob = post.uploadState?.blob as File;

  if (blob) {
    const uploadInfo = await retry(() =>
      newgraphClient.api.post.uploadCreate({
        filename: blob.name,
        targetId: post.id!,
        contentType: post.contentType!
      })
    );

    const r = await retry(() => {
      return fetch(uploadInfo.data.url as string, {
        method: "PUT",
        body: blob,
      });
    });

    if (r.status != 200) {
      throw new Error(
        `post-create: The post was created but couldn't upload the file, error: ${await r.json()}`
      );
    }
  }
};

export const uploadStages = [
  createPostOnServer,
  updateAndAttach,
  uploadFile,
] as [
    typeof createPostOnServer,
    typeof updateAndAttach,
    typeof uploadFile,
  ];