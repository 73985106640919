import { MoodReadResponse, PostReadResponse, UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import Dexie from "dexie";
import { Store } from "./cache";
import { NewgraphApi } from "./clients/newgraph";
import { newgraphBaseUrl } from "./config";
import { token, win } from "./actions/auth";
import { setNewgraphWebsocketToken } from "./eventHandlers";

type FolderVotingState = {
    lastVotedPostId?: string,
    lastLoadedPage?: number,
    votingComplete?: boolean,
    score?: number // should come in MoodReadResponse
}

type PostVotingState = {
    vote?: number,
}

type LocalFolderExhancements = {
    oneOnOne?: string,
    future?: boolean
}

export const POST_UPLOAD_STATE_SEQUENCE = ["preparing", "created", "attached", "upload-requested", "uploaded"];
export type PostUploadState = {
    status: "preparing" | "created" | "attached" | "upload-requested" | "uploaded",
    done?: boolean
    filename: string,
    blob: Blob,
    thumb: string,
    foldersToAttach: { id: string }[]
}

export type EnrichedPost = PostReadResponse & PostVotingState & { uploadState?: PostUploadState, thumbUrl?: string };
export type EnrichedFolder = MoodReadResponse & FolderVotingState & LocalFolderExhancements;

type Tables = {
    post: Dexie.Table<EnrichedPost, string>;
    user: Dexie.Table<UserReadPublicResponse, string>;
    folder: Dexie.Table<EnrichedFolder, string>;
    // uploads: Dexie.Table<PostReadResponse, string>;
};
const indices = {
    post: "id,created,author.username",
    user: "id,created,username",
    folder: "id,created,username,score",
    // uploads: "id",
}

export const cache = Store<Tables>("iosdk-cache", indices);

const CACHE_VERSION_STORE = "__iosdk_cache_version";

const DEPRECATED_CACHE_VERSIONS = [1210103023,1030232100,202306281530,202306281550];
const CACHE_VERSION = 202408082144;

const maybeResetAndOpenCache = () => {
    console.log("Checking", CACHE_VERSION_STORE);
    if (typeof window == "undefined")
        return;

    const currVersion = localStorage.getItem(CACHE_VERSION_STORE);
    if (DEPRECATED_CACHE_VERSIONS.includes(Number(currVersion as any)) || !(Number(currVersion) >= CACHE_VERSION)) {
        console.log("Cache is old, deleting");
        cache.delete()
            .then(() => {
                localStorage.setItem(CACHE_VERSION_STORE, CACHE_VERSION.toString())
                // cache.open();
                window.location.reload();
            })
    } else {
        cache.open();
        console.log(CACHE_VERSION_STORE, "cache is at latest ", currVersion)
    }

    if(typeof window != 'undefined') 
        (window as any).cache = cache;
}

maybeResetAndOpenCache();




// const token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVkZW50aWFsIjp7Im9yaWdpbiI6ImZpcmViYXNlIn0sImlkZW50aXR5Ijp7ImlkIjoiYjBiZjNmNTgtYTAwZi1jYmZiLTNlNTgtZDBlM2YyNGMwMGExIiwidXNlcm5hbWUiOiJ0ZXN0YWFhZWcuaW8iLCJwaG9uZSI6Iis0MjAxMTExMTExMTQifSwicmVxdWVzdG9yIjoibnVvdmF2aXRhLmlvIiwic2NvcGVzIjpbXSwiY29uZmlnIjp7ImNyZWF0ZWQiOiIyMDIzLTAzLTIwVDE5OjE5OjIxLjIxNVoiLCJleHBpcmVzIjoiMjAyMy0wMy0yMFQxOTo0OToyMS4yMTVaIiwicmVuZXdhYmxlIjp0cnVlfSwidHJhY2UiOnsib3JpZ2luYWwiOiIyMDIzLTAzLTIwVDE5OjE5OjIxLjIxNVoiLCJnZW5lcmF0aW9uIjowfSwicmVxdWVzdCI6eyJyZWZlcmVyIjoiaHR0cHM6Ly93d3cubmV3Zm9ydW0ueHl6IiwiYXBwT3duZXIiOiJudW92YXZpdGEuaW8iLCJyZWRpcmVjdFVybCI6Imh0dHBzOi8vd3d3Lm5ld2ZvcnVtLnh5eiIsInNjb3BlcyI6W119LCJhdXRob3JpdHkiOiJhdXRoLm5ld3NhZmUub3JnIiwidmVyc2lvbiI6IjEiLCJpYXQiOjE2NzkzMzk5NjF9.LE0YME3HoTbNG7VrZVzxemshoDr3heTVe4BTG2BWROpidyUAElZigzsamzUdWu_mc2Os3NAgzp3MF36F4ffGoflUAUDGMA2alu03VZpu3edNuTljb9c6WBjEw4ADBNbkLvij5AgBErbjfhYJJNcgIu_Db5zMcggjauUG-kW2knlqZX7-RaKZQylJe2G1WMYRr13uppVaiQM8qxiOVmmOVcmiiGOF6IwmJlOWsHoCLD_XZ3HbXKjb0cgEfv3kRFnvICNb80LcCR60PD8b4agPg1MRDuwsJyiVjc2-qrMFfdy-9A-u-mKu1wnj5gi8YTnXUl4smCSqNvET56zb9D3RdxiFktro9XNcL6Lsof8ypjr6CZLW2E03MDLV7IzT5z7v9_aqzb6Rbmc6G5kdbYgj1Nx6P_EVZvkgKeuzSm-bfKc50ATvEKSubQVxKhu0hcvbEj-zkd5pvpagThWI9NR83noz1HS8QdtQIFZUjQtoFF_w_p8J3pTIfBiK8aJEE-GX";

export const newgraphClientManager = NewgraphApi();


newgraphClientManager.initialize(newgraphBaseUrl);

export const newgraphClient = newgraphClientManager;

setTimeout(() => {
    token.subscribe((token) => {
        // newgraphClient.setSecurityData({ token: token || "" });
        newgraphClientManager.updateToken(`${token.jwt}`);

        setNewgraphWebsocketToken(token.jwt || "");
    });
})



// token.value = { jwt: "..." }
