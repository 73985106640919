import { Signal, signal } from "@preact/signals-react";
import { progressiveHandler } from "../ProgressiveHandler";
import { newgraphClient } from "..";
import { _current } from "./auth";
import { XPromise } from "../utils/XPromise";
import { chunk } from "lodash";

const RESET_BEFORE = "2024-12-11T12:10Z";

const localStorage =
    typeof window !== "undefined" && (window as any).localStorage;

const cache: Signal<{ items: any[], percentLoaded: number }> = signal({ items: [] as any[], percentLoaded: 0 });

const ACTIVITY_STREAM_LOCAL_STATE = "activity-stream-state";

const activityStreamState: { lastKnown: string, promise: null | XPromise } = { lastKnown: "", promise: null };

try {
    if (localStorage)
        activityStreamState.lastKnown = JSON.parse(window.localStorage.getItem(ACTIVITY_STREAM_LOCAL_STATE) || "{}").lastKnown;
} catch (ex) { };

// const refreshLastKnown =  new Date(RESET_BEFORE).getTime() < new Date(activityStreamState.lastKnown).getTime();
const lastKnown = activityStreamState.lastKnown;
// ?
// new Date(RESET_BEFORE).getTime() < new Date(activityStreamState.lastKnown).getTime() ?
//     activityStreamState.lastKnown :
//     ""
// : "";


export const getActivityStream = async (processor: (items: any[]) => void) => {
    // if(!_current.value?.id)
    //     return Promise.resolve(); // too soon


    activityStreamState.promise = new XPromise();

    let newLastKnown = lastKnown;
    let nextToken = "";
    // if(1) return;
    do {
        const res = await newgraphClient.api.user.activityStreamList(newLastKnown ? { after: newLastKnown || "" } : {});

        nextToken = (res.data as any)?.nextToken; //?.updatedAt;//(res.data as any).items.map((d: { updatedAt:  }) => new Date(d.updatedAt).getTime());
        const items = ((res.data as any).Items || []);
        cache.value = {
            items: [...items.sort((a: { updated: string }, b: { updated: string }) => new Date(b.updated).getTime() - new Date(a.updated).getTime()), ...(cache.value?.items || [])],
            percentLoaded: cache.value.percentLoaded + 1
        }
        const newLastKnownMs = // Math.max(...[newLastKnown || 0, lastKnown || 0, ...items.map((i: { updated: string }) => i.updated)].map(isoDate => new Date(isoDate).getTime()));
            Math.max(...[newLastKnown || 0, lastKnown || 0, ...items.map((i: { updated: string }) => i.updated)].map(isoDate => new Date(isoDate).getTime()));

        if (newLastKnown != new Date(newLastKnownMs).toISOString())
            newLastKnown = new Date(newLastKnownMs).toISOString();
        else
            nextToken = "";

        localStorage.setItem(ACTIVITY_STREAM_LOCAL_STATE, JSON.stringify({ ...activityStreamState, lastKnown: newLastKnown }))

        const chunked = chunk(items, 50);
        for (const _items of chunked) {
            await processor(_items);
            // await new Promise(res => setTimeout(res, 50));
        }

        if (cache.value.percentLoaded > 10)
            nextToken = ""; //activityStreamState.promise?.resolve();

    } while (nextToken)

    activityStreamState.promise?.resolve();

    // console.log(res.data);

    // const latest = (res.data as any).items.map((d: { updatedAt:  }) => new Date(d.updatedAt).getTime());
    // localStorage.setItem({ ...activityStreamState, lastKnown: latest.toISOString() })

    // return cache;
}

export const activityStream = () =>
    progressiveHandler(
        // req/cache key
        undefined,

        // cache query
        cache, //Promise.resolve(current) as PromiseExtended,

        // how to fetch
        async (progress, cache) => {
            if (activityStreamState.promise)
                await activityStreamState.promise!; // newgraphClient.api.user.activityStreamList(lastKnown ? { after: lastKnown || "" } : {});

            await Promise.resolve();

            return { ...progress, done: true };
        },
        {
            autostart: true
        }
    );

